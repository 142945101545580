import { Link } from "gatsby";
import React, { useRef, useState, useEffect } from "react";
import Modal from 'react-modal';
import { isMobile, isTablet, isBrowser, isSafari } from 'react-device-detect';

import Airplane from "../components/airplane.js"
import ParallaxImage from "../components/parallaxImage.js"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { gsap } from "../vendor/gsap/src/index.js";
import { CustomEase } from "../vendor/gsap/src/CustomEase.js";
import { ScrollTrigger } from "../vendor/gsap/src/ScrollTrigger.js";
import customer_journey from "./data/customer_journey.json";
import "./customerJourney.scss";

gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTrigger);

let customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '20px 20px 35px',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        borderRadius: '20px',
        backgroundColor: '#00357d',
        border: 0,
        color: 'white'
    }
};

if (isMobile && isTablet === false) {
    customStyles.content.width = '300px';
}

if (isMobile && isTablet) {
    customStyles.content.width = '600px';
}
let mainText = '';
let subText = '';
let firstContainerTitle = '';
let firstContainerText = '';
let customerJourneyTitle = '';
let finalContainerTitle = '';
let finalContainerText = '';

let mobileImage = '';
let tabletImage = '';
let desktopImage = '';

let leftParallax = null;
let rightParallax = null;

let previousScroll = 0;

const refsArray = [];

const cleanup = () => {
    document.documentElement.style.overflow = 'unset';
}

const CustomerJourney = () => {
    for (const title of customer_journey.titles) {
        if (title.indicator === 'main-title-text') {
            mainText = title;
        }
        if (title.indicator === 'sub-title-text') {
            subText = title;
        }
        if (title.indicator === 'first-container-title') {
            firstContainerTitle = title.value;
        }
        if (title.indicator === 'first-container-text') {
            firstContainerText = title;
        }
        if (title.indicator === 'customer-journey-title') {
            customerJourneyTitle = title.value;
        }
        if (title.indicator === 'final-container-title') {
            finalContainerTitle = title.value;
        }
        if (title.indicator === 'final-container-text') {
            finalContainerText = title;
        }
    }

    for (const image of customer_journey.backgroundImages) {
        if (image.indicator === 'home-customer-journey-buttons-background') {
            if (image.deviceType === 'mobile' && (image.backgroundImage != null && image.backgroundImage !== '')) {
                mobileImage = image.backgroundImage;
            }
            if (image.deviceType === 'tablet' && (image.backgroundImage != null && image.backgroundImage !== '')) {
                tabletImage = image.backgroundImage;
            }
            if (image.deviceType === 'desktop' && (image.backgroundImage != null && image.backgroundImage !== '')) {
                desktopImage = image.backgroundImage;
            }
        }
        if (image.indicator === 'home-left-parallax') {
            leftParallax = image;
        }
        if (image.indicator === 'home-right-parallax') {
            rightParallax = image;
        }
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [mobile, setMobile] = useState(null);
    const [moduleData, setModuleData] = useState(null);
    const [moduleSlug, setModuleSlug] = useState(null);
    const [safari, setSafari] = useState(null);
    const [airplaneRefs, setAirplaneRefs] = useState([]);
    const [backgroundImage, setBackgroundImage] = useState([]);
    const groupsRef = useRef(null);

    const handleOpenModal = (module) => {
        setModuleSlug(module.slug);
        setModuleData(module);
        setIsOpen(true);
        previousScroll = window.scrollY;
    }

    const handleCloseModal = () => {
        setModuleSlug(null);
        setModuleData(null);
        setIsOpen(false);
    }

    const scrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ block: "center" });
        } else {
            if (ref && ref.offsetTop && ref.offsetHeight) {
                window.scrollTo(0, ref.offsetTop + document.querySelector('.header-container').offsetHeight);
            }
        }
    }

    const onScroll = () => {
        if (modalIsOpen) {
            if (previousScroll !== window.scrollY) {
                handleCloseModal();
            }
            previousScroll = window.scrollY;
        }

        if (showVideo === false) {
            setShowVideo(true);
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('touchmove', onMouseMove);
        }
    }

    const onMouseMove = () => {
        if (showVideo === false) {
            setShowVideo(true);
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('touchmove', onMouseMove);
        }
    }

    if (typeof window !== "undefined") {
        window.addEventListener('scroll', onScroll, true);
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('touchmove', onMouseMove);
    }

    useEffect(() => {
        let elements = document.querySelectorAll('.row-fadeIn');
        let animations = [];
        elements.forEach((container, i) => {
            animations.push(gsap.to(container, 1, {
                opacity: 1,
                y: 0,
                duration: .1,
                ease: "power4.in",
                scrollTrigger: {
                    id: `fadeIn_trigger_${i}`,
                    trigger: container,
                    start: "top 90%",
                    end: "bottom 0%"
                }
            }));
        });

        Modal.setAppElement('body');
        if (isBrowser) {
            setMobile(true);
        } else {
            setMobile(false);
        }

        if (isMobile && isTablet === false && isBrowser === false) {
            if (mobileImage !== '') {
                setBackgroundImage(mobileImage);
            } else if (desktopImage !== '') {
                setBackgroundImage(desktopImage);
            } else if (tabletImage !== '') {
                setBackgroundImage(tabletImage);
            }
        } else if (isMobile && isTablet && isBrowser === false) {
            if (tabletImage !== '') {
                setBackgroundImage(tabletImage);
            } else if (mobileImage !== '') {
                setBackgroundImage(mobileImage);
            } else if (desktopImage !== '') {
                setBackgroundImage(mobileImage);
            }
        } else {
            if (desktopImage !== '') {
                setBackgroundImage(desktopImage);
            } else if (tabletImage !== '') {
                setBackgroundImage(tabletImage);
            } else if (mobileImage !== '') {
                setBackgroundImage(mobileImage);
            }
        }

        if (isSafari === true) {
            setSafari(true);
        } else {
            setSafari(false);
        }

        return () => {
            // window.removeEventListener('scroll', onScroll, true);
            elements.forEach((container, i) => {
                ScrollTrigger.getById(`fadeIn_trigger_${i}`).kill(true);
                animations[i].kill(true);
            });
        }
    }, [mobile, backgroundImage]);

    return (
        <div>
            <div className="title-container">
                <span className="parallax-container start-container">
                    <ParallaxImage className="left-parallax" slug="left-parallax" safariCheck={safari} timeline={leftParallax.parallaxAnimationType} displayType={leftParallax.parallaxDisplayType} images={leftParallax} />
                    <ParallaxImage className="right-parallax" slug="right-parallax" safariCheck={safari} timeline={rightParallax.parallaxAnimationType} displayType={rightParallax.parallaxDisplayType} images={rightParallax} />
                </span>
                <button className="text-button" onClick={() => scrollToRef(groupsRef)}>

                    <h1>
                        <strong>
                            {mainText.value}
                        </strong>
                    </h1>

                    <h1>
                        {subText.value}
                    </h1>
                </button>
                <button aria-label="Scroll to ref button" className="arrow bounce" onClick={() => scrollToRef(groupsRef)}>
                    <div className="mobile-arrow"></div>
                </button>
            </div>
            <div className="customer-journey-container">
                <div className="start-container container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="center-container">
                                <h1>{firstContainerTitle}</h1>
                                <p className="inline">{firstContainerText.value} </p>
                                {(firstContainerText.richValue && firstContainerText.richValue.html) &&
                                    <div className="html-text inline" dangerouslySetInnerHTML={{ __html: firstContainerText.richValue.html }} />
                                }
                                <Link className="more-about-button" to="/over-ons">Meer over ons</Link>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="video-container mobile">
                                {showVideo === true ? (
                                    <span>
                                        <div style={{ position: 'absolute', height: 0, paddingBottom: '40%', backgroundColor: 'transparent', zIndex: 1, width: '100%' }}></div>
                                        <div style={{ position: 'relative', height: 0, paddingBottom: '56.25%' }}>
                                            <iframe className='sproutvideo-player lazyload' title="travelspirit video" src='https://videos.sproutvideo.com/embed/119cd7be1f1ce6cf98/caf23027b4a454fb?autoPlay=true&amp;playerTheme=light&amp;fullscreenButton=true&amp;seekBar=false&amp;settingsButton=false&amp;loop=true&amp;volume=0' style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }} frameBorder='0' allowFullScreen></iframe>
                                        </div>
                                    </span>
                                ) : (
                                    <span>
                                        <div>
                                            <iframe className='sproutvideo-player lazyload' title="travelspirit video placeholder" src='https://videos.sproutvideo.com/embed/119cd7be1f1ce6cf98/caf23027b4a454fb?playerTheme=light&amp;fullscreenButton=true&amp;seekBar=false&amp;settingsButton=false&amp;loop=true&amp;volume=0' style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }} frameBorder='0' allowFullScreen></iframe>
                                        </div>
                                    </span>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="customer-journey-container airplane-wrapper">
                    <div className="summary-container container" style={{ backgroundImage: `url(${backgroundImage})` }} ref={groupsRef}>
                        <h1>{customerJourneyTitle}</h1>
                        <div id="summary-menu-container">
                            <ul className="summary-menu">
                                {customer_journey.moduleGroups.map((moduleGroup, i) => {
                                    return <li key={moduleGroup.slug + 'ref'}>
                                        <button className="orange-button" onClick={() => scrollToRef(refsArray[i])}><span>{('0' + (i + 1)).slice(-2)}</span> {moduleGroup.moduleGroupName}</button>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                    {airplaneRefs.length === customer_journey.moduleGroups.length &&
                        <Airplane blocks={airplaneRefs} />
                    }

                    {customer_journey.moduleGroups.map((moduleGroup, i) => {
                        return <div id={moduleGroup.slug + "-container"} className={moduleGroup.containerColor + "-container container"} ref={ref => {
                            refsArray[i] = ref;
                            if (airplaneRefs.indexOf(moduleGroup.slug + "-container") < 0) {
                                setAirplaneRefs(airplaneRefs.concat([moduleGroup.slug + "-container"]));
                            }
                        }} key={moduleGroup.slug}>
                            {mobile === true ? (
                                <div className="print-ignore row justify-content-between row-fadeIn">
                                    {i % 2 === 1 && (
                                        <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn parallax-container"}>
                                            <ParallaxImage slug={moduleGroup.slug} safariCheck={safari} timeline={moduleGroup.parallaxAnimationType} displayType={moduleGroup.parallaxDisplayType} images={moduleGroup} />
                                        </div>
                                    )}
                                    <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn"}>
                                        <div className="row">
                                            <span className="counter">{('0' + (i + 1)).slice(-2)}</span>
                                            <h1>{moduleGroup.moduleGroupName}</h1>
                                            <p>{moduleGroup.description}</p>
                                            {moduleGroup.modules && moduleGroup.modules.length > 0 && <span>
                                                <h2>Modules:</h2>
                                                <hr />
                                                <ul className="module-list">
                                                    {moduleGroup.modules.map((module, index) => {
                                                        if (index > 5) return null
                                                        return <li className="module-list-item" key={module.slug}>
                                                            <button onClick={() => handleOpenModal(module)}>
                                                                {module.moduleName}
                                                            </button>
                                                        </li>
                                                    })}
                                                </ul>
                                            </span>}
                                        </div>
                                        <div className="row">
                                            <Link className="module-reference" key={moduleGroup.slug} to={`/customer-journey/${moduleGroup.slug}`}>{moduleGroup.moreLinkText}
                                                <div className="icon baseline">
                                                    <ArrowRightAltIcon />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    {i % 2 === 0 && (
                                        <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn parallax-container"}>
                                            <ParallaxImage slug={moduleGroup.slug} safariCheck={safari} timeline={moduleGroup.parallaxAnimationType} displayType={moduleGroup.parallaxDisplayType} images={moduleGroup} />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="print-ignore row justify-content-between row-fadeIn">
                                    <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn"}>
                                        <div className="row">
                                            <span className="counter">{('0' + (i + 1)).slice(-2)}</span>
                                            <h1>{moduleGroup.moduleGroupName}</h1>
                                            <p>{moduleGroup.description}</p>
                                            {moduleGroup.modules && moduleGroup.modules.length > 0 && <span>
                                                <h2>Modules:</h2>
                                                <hr />
                                                <ul className="module-list">
                                                    {moduleGroup.modules.map((module, index) => {
                                                        if (index > 5) return null
                                                        return <li className="module-list-item" key={module.slug}>
                                                            <button onClick={() => handleOpenModal(module)}>
                                                                {module.moduleName}
                                                            </button>
                                                        </li>
                                                    })}
                                                </ul>
                                            </span>}
                                        </div>
                                        <div className="row">
                                            <Link className="module-reference" key={moduleGroup.slug} to={`/customer-journey/${moduleGroup.slug}`}>{moduleGroup.moreLinkText}
                                                <div className="icon baseline">
                                                    <ArrowRightAltIcon />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn"}>
                                        <div className="parallax-container">
                                            <ParallaxImage slug={moduleGroup.slug} safariCheck={safari} timeline={moduleGroup.parallaxAnimationType} displayType={moduleGroup.parallaxDisplayType} images={moduleGroup} />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="print print-container">
                                <div className="row">
                                    <span className="counter">{('0' + (i + 1)).slice(-2)}</span>
                                    <h1>{moduleGroup.moduleGroupName}</h1>
                                    <p>{moduleGroup.description}</p>
                                    {moduleGroup.modules && moduleGroup.modules.length > 0 && <span>
                                        <h2>Modules:</h2>
                                        <hr />
                                        <ul className="module-list">
                                            {moduleGroup.modules.map((module, index) => {
                                                if (index > 5) return null
                                                return <li className="module-list-item" key={module.slug}>
                                                    <button onClick={() => handleOpenModal(module)}>
                                                        {module.moduleName}
                                                    </button>
                                                </li>
                                            })}
                                        </ul>
                                    </span>}
                                </div>
                                <div className="row">
                                    <Link className="module-reference" key={moduleGroup.slug} to={`/customer-journey/${moduleGroup.slug}`}>{moduleGroup.moreLinkText}
                                        <div className="icon baseline">
                                            <ArrowRightAltIcon />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="print print-container">
                                <div className="parallax-container">
                                </div>
                            </div>
                        </div>
                    })}

                    <div id="bottom-container" className="white-container final-container container">
                        <h2>{finalContainerTitle}</h2>
                        <p>{finalContainerText.value}</p>
                        {(finalContainerText.richValue && finalContainerText.richValue.html) &&
                            <div className="html-text inline" dangerouslySetInnerHTML={{ __html: finalContainerText.richValue.html }} />
                        }
                        <div>
                            <ul className="button-menu">
                                <li className="demo-button-container">
                                    <button className="demo-button">
                                        <Link id="demo-container-button" key="demo-button" to={`/demo`}>Demo inplannen</Link>
                                    </button>
                                    <p>Online sessie van 30 minuten</p>
                                </li>
                                <li className="more-about-container">
                                    <button id="meet-container-button" className="more-about-button">
                                        <Link key="meet-button" to={`/demo-afspraak`}>Verkennend gesprek</Link>
                                    </button>
                                    <p>Stel online al jouw vragen</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Modal isOpen={modalIsOpen}
                        onRequestClose={handleCloseModal}
                        style={customStyles}
                    >
                        {moduleData && moduleData.moduleTooltip && moduleData.moduleName && (
                            <div>
                                <h1>{moduleData.moduleName}</h1>
                                <p>{moduleData.moduleTooltip}</p>
                                <Link className="modal-module-reference" key={moduleSlug} onClick={() => cleanup()} to={`/modules/${moduleSlug}`}>Meer over {moduleData.moduleName}
                                    <div className="icon baseline">
                                        <ArrowRightAltIcon />
                                    </div>
                                </Link>
                            </div>
                        )}
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default CustomerJourney
