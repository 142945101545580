import React from "react";
import { gsap } from "../vendor/gsap/src/index.js";
import { CustomEase } from "../vendor/gsap/src/CustomEase.js";
import { ScrollTrigger } from "../vendor/gsap/src/ScrollTrigger.js";
import { MotionPathPlugin } from "../vendor/gsap/src/MotionPathPlugin.js";
import "./airplane.scss";

class Airplane extends React.Component {
    canvas;
    ctx;
    trigger;
    events = [];
    plane = "#animated-plane";
    callbackMethod;

    constructor() {
        super();
        gsap.registerPlugin(MotionPathPlugin);
        gsap.registerPlugin(CustomEase);
        gsap.registerPlugin(ScrollTrigger);
    }

    componentDidMount() {
        let lastType = 'desktop';
        let windowWidth = window.innerWidth
        let windowHeight = window.innerHeight
        ScrollTrigger.matchMedia({
            // desktop
            "(min-height: 501px)": () => {
                lastType = 'desktop';
                this.generateList(lastType);
            },
          
            // mobile
            "(max-height: 500px)": () => {
                lastType = 'mobile';
                this.generateList(lastType);
            },
        });
        

        let timeout;
        window.addEventListener('resize', this.callbackMethod = () => {
            if(window.innerHeight === windowHeight && window.innerWidth === windowWidth) {
                return;
            }
            
            windowHeight = window.innerHeight;
            windowWidth = window.innerWidth;

            clearTimeout(timeout);
            timeout = setTimeout(() => {
                this.trigger.kill(true);
                if (this.canvas) {
                    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    this.generateList(lastType);
                }
            }, 250);
        });
    }

    componentWillUnmount()
    {
        if (this.trigger)
            this.trigger.kill(true);

        window.removeEventListener('resize', this.callbackMethod);
        // this.events.forEach((event, i) => {
        //     ScrollTrigger.getById(`fadeIn_trigger_${i}`).kill(true);
        //     event.kill(true);
        // });
    }

    // initGsapEvents(containers)
    // {
        

    //     containers.forEach((container, i) => {
    //         this.events.push(gsap.to(container, 1, {
    //             opacity: 1,
    //             y: 0,
    //             duration: .1,
    //             ease: "power4.in",
    //             scrollTrigger: {
    //                 id: `fadeIn_trigger_${i}`,
    //                 markers: false,
    //                 trigger: container,
    //                 start: `top 80%`,
    //                 end: `bottom 20%`,
    //                 toggleActions: "play none none none"
    //             }
    //         }));
    //     });
    // }

    generateList(type) {
        let blockHeights = [];
        this.props.blocks.forEach(block => {
            let element = document.getElementById(block);
            if (element && element.clientHeight) {
                blockHeights.push(element.clientHeight);
            }
        });

        this.activateDraw(type);
        this.draw(type, blockHeights);
    }

    activateDraw(type) {
        this.canvas = document.getElementById('canvas');
        if (this.canvas) {
            this.canvas.setAttribute('width', '600px');
            let minusHeight = (this.props.type === 'voor-wie') ? 830 : 600;
            this.canvas.setAttribute('height', document.querySelector('.airplane-wrapper').clientHeight - minusHeight);
            this.ctx = this.canvas.getContext('2d');
        }
    }

    draw(type, blockHeights) {
        let currentHeight = 40;
        let newHeight = 0;
        let boundaries = [
            [600, -70, 290, 1.03, 1.52],
            [200, 250, 290],
            [360, 205, 290],
            [230, 270, 290],
            [360, 330, 290],
            [230, 190, 290],
            [340, 246, 290],
            [230, 300, 290],
            [360, 257, 340],
            [300, 440, 210]
        ];
        if(this.props.type === 'voor-wie') {
            delete boundaries[8];
            boundaries = boundaries.filter(boundaries => boundaries);
        }
        let planePaths = [];
        
        this.ctx.lineWidth = 2;
        this.ctx.setLineDash([10, 15])

        this.ctx.beginPath();
        this.ctx.moveTo(350, currentHeight);

        let baseOffsetX = 322;
        let baseOffsetY = 60;

        blockHeights.forEach((height, i) => {
            let current = {};

            if (i === 0) {
                current = { startX: 320, startY: 75 };
                newHeight = currentHeight + height + 200; //200 is top margin of airplane
            } else {
                current = { startX: boundaries[i - 1][2], startY: newHeight };
                currentHeight = newHeight;
                newHeight = currentHeight + height;
            }

            if (i === blockHeights.length - 1) {
                newHeight += 50;
            }
            this.ctx.quadraticCurveTo(boundaries[i][0], currentHeight + boundaries[i][1], boundaries[i][2], newHeight);

            let planeOffsetX = (boundaries[i][3]) ? boundaries[i][3] : 1;
            let planePathX = (boundaries[i][0] * planeOffsetX) - baseOffsetX;
            let planeOffsetY = boundaries[i][4] ? boundaries[i][4] : 1;
            let planePathY = currentHeight + (boundaries[i][1] * planeOffsetY) - baseOffsetY;
            planePaths.push(`M${current.startX - baseOffsetX},${current.startY - baseOffsetY} Q${planePathX},${planePathY} ${boundaries[i][2] - baseOffsetX},${newHeight - baseOffsetY}`);
        });

        if (planePaths.length > 0) {
            this.ctx.quadraticCurveTo(140, newHeight + 55, 200, newHeight + 120);
            this.ctx.quadraticCurveTo(240, newHeight + 145, 310, newHeight + 90);

            // let lastPath = `M-112,${newHeight - baseOffsetY} Q${140 - baseOffsetX},${newHeight + 55 - baseOffsetY} ${200 - baseOffsetX},${newHeight + 120 - baseOffsetY} Q${240 - baseOffsetX},${newHeight + 145 - baseOffsetY} ${310 - baseOffsetX},${newHeight + 90 - baseOffsetY}`;
            this.ctx.strokeStyle = '#00357d';
            this.ctx.stroke();
            planePaths[planePaths.length - 1] = planePaths[planePaths.length - 1] + ` Q${140 - baseOffsetX},${newHeight + 55 - baseOffsetY} ${200 - baseOffsetX},${newHeight + 120 - baseOffsetY} Q${240 - baseOffsetX},${newHeight + 145 - baseOffsetY} ${310 - baseOffsetX},${newHeight + 110 - baseOffsetY}`;
            this.createPlaneAnimations(type, planePaths);
        }
    }

    createPlaneAnimations(type, planePaths) {
        let tl = gsap.timeline();
        tl = this.createTimeline(type, planePaths, tl);
        let lastDir = 1;
        if(type === 'desktop') {
            this.trigger = ScrollTrigger.create({
                trigger: ".airplane-wrapper",
                animation: tl,
                start: "top 10%",
                end: "bottom 90%",
                scrub: 1,
                onLeaveBack: self => {
                    if (document.querySelector(this.plane + ' img')) {
                        document.querySelector(this.plane + ' img').setAttribute('style', 'transform: rotate(18deg)');
                    }
                    lastDir = 1;
                },
                onUpdate: self => {
                    if (self.direction === lastDir) return;
    
                    if (self.direction === -1) {
                        lastDir = -1;
                        document.querySelector(this.plane + ' img').setAttribute('style', 'transform: rotate(-153deg) translate(12px, 10px);');
                    } else {
                        lastDir = 1;
                        document.querySelector(this.plane + ' img').setAttribute('style', 'transform: rotate(18deg)');
                    }
                }
            });
        } else {
            this.trigger = ScrollTrigger.create({
                trigger: ".airplane-wrapper",
                animation: tl,
                start: "top -80%",
                end: "bottom 200%",
                scrub: 1,
                onLeaveBack: self => {
                    if (document.querySelector(this.plane + ' img')) {
                        document.querySelector(this.plane + ' img').setAttribute('style', 'transform: rotate(18deg)');
                    }
                    lastDir = 1;
                },
                onUpdate: self => {
                    if (self.direction === lastDir) return;

                    if (self.direction === -1) {
                        lastDir = -1;
                        document.querySelector(this.plane + ' img').setAttribute('style', 'transform: rotate(-153deg) translate(12px, 10px);');
                    } else {
                        lastDir = 1;
                        document.querySelector(this.plane + ' img').setAttribute('style', 'transform: rotate(18deg)');
                    }
                }
            });
        }

        
    }

    render() {
        return <div className="canvas-container">
            <div className="animation-background"></div>
            <canvas id="canvas"></canvas>
            {/* <svg width="600" height="8410" viewBox="0 0 600 8410" id="svg">
                 { <path d="M100,250 Q250,100 400,250 T250,250" /> }
                    <path d="M350,40 Q600, -30 290,750" stroke="#ff0000" width="1" fill="transparent"></path>
                    <path d="M290,750 Q200, 1000 290,1300" stroke="#ff0000" width="1" fill="transparent"></path>
                    <path d="M294,1315 Q360, 1520 290,1830" stroke="#ff0000" width="1" fill="transparent"></path>
                    
                 </svg> */}
            <div id="animated-plane">
                <img src="https://media.travelspirit.nl/carousel/sm/airplane.png" alt="airplane" loading="lazy" />
            </div>
        </div>;
    }

    createTimeline(type, planePaths, tl)
    {
        if (planePaths[0]) {
            tl.to(this.plane, {
                duration: 1,
                ease: (type === 'desktop') ? "power2.inOut" : "linear",
                motionPath: {
                    path: planePaths[0],
                    autoRotate: true
                }
            });
        }

        if (planePaths[1]) {
            tl.to(this.plane, {
                duration: 1,
                ease: (type === 'desktop') ? "power2.in" : "linear",
                motionPath: {
                    path: planePaths[1],
                    autoRotate: true
                }
            });
        }

        if (planePaths[2]) {
            tl.to(this.plane, {
                duration: 1,
                ease: (type === 'desktop') ? "power2.out" : "linear",
                motionPath: {
                    path: planePaths[2],
                    autoRotate: true
                }
            });
        }
        if (planePaths[3]) {
            tl.to(this.plane, {
                duration: 1,
                ease: (type === 'desktop') ? "power2.inOut" : "linear",
                motionPath: {
                    path: planePaths[3],
                    autoRotate: true
                }
            });
        }
        if (planePaths[4]) {
            tl.to(this.plane, {
                duration: 1,
                ease: (type === 'desktop') ? "power2.in" : "linear",
                motionPath: {
                    path: planePaths[4],
                    autoRotate: true
                }
            });
        }
        if (planePaths[5]) {
            tl.to(this.plane, {
                duration: 1,
                ease: (type === 'desktop') ? "power2.out" : "linear",
                motionPath: {
                    path: planePaths[5],
                    autoRotate: true
                }
            });
        }
        if (planePaths[6]) {
            tl.to(this.plane, {
                duration: 1,
                ease: (type === 'desktop') ? "power2.inOut" : "linear",
                motionPath: {
                    path: planePaths[6],
                    autoRotate: true
                }
            });
        }
        if (planePaths[7]) {
            tl.to(this.plane, {
                duration: 1,
                ease: (type === 'desktop') ? "power2.in" : "linear",
                motionPath: {
                    path: planePaths[7],
                    autoRotate: true
                }
            });
        }
        if (planePaths[8]) {
            tl.to(this.plane, {
                duration: 1,
                ease: (type === 'desktop') ? "power2.out" : "linear",
                motionPath: {
                    path: planePaths[8],
                    autoRotate: true
                }
            });
        }
        if (planePaths[9]) {
            tl.to(this.plane, {
                duration: 1,
                ease: (type === 'desktop') ? "power2.inOut" : "linear",
                motionPath: {
                    path: planePaths[9],
                    autoRotate: true
                }
            });
        }

        return tl;
    }

};

export default Airplane
